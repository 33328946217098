<template>
	<div>
		<div class="steps">
			<h4 class="text-center template-color--text text--darken-2">
				Yalnızca {{ stepCount }} adımda bu araca sahip olmak için aşağıda ki adımları izlemeniz yeterli.
			</h4>
			<v-list flat tile light color="transparent">
				<v-list-item class="px-0" v-for="(step, index) in getSite.carReservationStep" :key="`step-${index}`">
					<v-list-item-icon>
						<v-img contain max-height="100" max-width="100" :src="step.icon.name"></v-img>
					</v-list-item-icon>
					<v-list-item-content>
						<div class="text-h6">
							{{ step.title }}
						</div>
						<div v-html="step.desc"></div>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>
		<div class="faq">
			<h3 class="py-5 template-color--text text--darken-2">Sıkça sorulan sorular</h3>
			<v-expansion-panels light flat accordion>
				<v-expansion-panel class="my-2" v-for="(faq, index) in getSite.carReservationFaq" :key="`faq-${index}`">
					<v-expansion-panel-header color="grey lighten-4">
						<h3>{{ faq.question }}</h3>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<div class="pt-5" v-html="faq.answer"></div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'ReservationStart',
	data() {
		return {};
	},
	computed: {
		...mapGetters({
			getSite: 'getSite',
		}),
		stepCount() {
			return this.getSite.carReservationStep.length;
		},
	},
};
</script>

<style></style>
